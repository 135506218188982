import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Link} from 'react-router-dom';
import { ApiData } from './contexts/ApiContext';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ApiData>
      <Link target="_blank" to={'https://wa.me/+5491121557430?text=Hola%20como%20estas?%20deseo%20información%20o%20servicio%20técnico%20para%20mi%20equipo.%20Gracias!'}>
        <div style={{
          backgroundImage: `url('/assets/img/whatsapp.svg')`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          width: 'fit-content',
          position: 'fixed',
          bottom: '30px',
          right: '20px',
          padding: '30px',
          zIndex: '999'
          }}>
        </div>
      </Link>
      <App />
    </ApiData>
  </BrowserRouter>
);