import { useEffect, useState } from "react";

export default function ApiBranch() {
    const [branch, setBranch] = useState([]);  // ESTO ES PARA USAR CON API DE Branch.

    useEffect(() => {
        fetchBranch();  // ESTO ES PARA USAR CON API DE Branch.
    }, []);

    // ESTO ES PARA USAR CON API DE Branch.
    const fetchBranch = async () => {
        try {
            const response = await fetch('https://admin.leiten.com.ar/api/getAllBranches');
            if (response.ok) {
                const data = await response.json();
                const filteredData = data.filter((_, index) => index !== 1); // Excluir la sucursal en la posición [1]
                setBranch(filteredData); // Establece las sucursales filtradas en el estado
                return;
            }
            throw new Error('Network response was not ok');
        } catch (error) {
            console.error('Error fetching branches:', error);
        }
    };

    return branch;
}