export async function getBrands() {
    try {
        const response = await fetch(`${process.env.REACT_APP_LEITEN_URL}/api/leiten/marcas`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': process.env.REACT_APP_LEITEN_ACCESS
            }
        });
            if (!response.ok) {
                throw new Error('Network from brands response was not ok');
            }
            return await response.json();

    } catch (error) {
        console.error('Error:', error);
        return 'error';
    }
}

export async function getProductsByBrand(slugbrand) {
    try {
        const response = await fetch(`${process.env.REACT_APP_LEITEN_URL}/api/leiten/marcas/` + slugbrand + `/productos`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': process.env.REACT_APP_LEITEN_ACCESS
            }
        });
            if (!response.ok) {
                throw new Error('Network from productsByBrands response was not ok');
            }
            
            return await response.json();

    } catch (error) {
        console.error('Error:', error);
        return 'error';
    }
}

export async function getProductById (id){
    try{
        const response = await fetch (`${process.env.REACT_APP_LEITEN_URL}/api/product/` + id,{
        //const response = await fetch (`http://localhost:8000/api/product/` + id,{
            method: "GET",
        });
        if (!response.ok) {
            throw new Error('Network from productById response was not ok');
        }
        return await response.json();

    } catch {
        return 'error';
    }
}
// CREO QUE NO SE ESTA USANDO
export async function getProductByCode (code){ 
    try{
        const response = await fetch (`${process.env.REACT_APP_LEITEN_URL}/api/leiten/productos/codigo/` + code,{
        //const response = await fetch (`http://localhost:8000/api/leiten/productos/codigo/` + code,{
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': process.env.REACT_APP_LEITEN_ACCESS
            }
        });
        if (!response.ok) {
            throw new Error('Network from productByCode response was not ok');
        }
        return await response.json();
        
    } catch {
        return 'error';
    }
}

export async function getKitMaintenance (){
    try{
        const response = await fetch (`${process.env.REACT_APP_LEITEN_URL}/api/leiten/kit-mantenimiento`,{
        //const response = await fetch (`http://localhost:8000/api/leiten/kit-mantenimiento`,{
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': process.env.REACT_APP_LEITEN_ACCESS
            }
        });
        if (!response.ok) {
            throw new Error('Network from KitMaintenance response was not ok');
        }
        return await response.json();
        
    } catch {
        return 'error';
    }
}

export async function getProductSearch(){
    try{
        const response = await fetch (`${process.env.REACT_APP_LEITEN_URL}/api/product-search`,{
        //const response = await fetch (`http://localhost:8000/api/product-search`,{
                method: "GET",
            });
            if (!response.ok) {
                throw new Error('Network from product Search response was not ok');
            }

            return await response.json();
        
    } catch {
        return 'error';
    }
}

export async function getProductPrice (id){
    try{
        const response = await fetch (`${process.env.REACT_APP_LEITEN_URL}/api/leiten/precios-producto/` + id,{
        //const response = await fetch (`http://localhost:8000/api/product-search`,{
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': process.env.REACT_APP_LEITEN_ACCESS
                }
            });
            if (!response.ok) {
                throw new Error('Network from productPrice response was not ok');
            }
            return await response.json();
        
    } catch {
        return 'error';
    }
}

export async function postSendEmail (data) {
    try {
        const tipo = data.tipo;
        const response = await fetch (`${process.env.REACT_APP_LEITEN_URL}/api/leiten/contacto-postVenta?tipo=${tipo}`, {
        //const response = await fetch (`http://localhost:8000/api/leiten/contacto-postVenta?tipo=${tipo}`,{
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    //en localhotst de leiten no usa autorizacion
                    'Authorization': process.env.REACT_APP_LEITEN_ACCESS
                },
                body: JSON.stringify(data)
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return await response.json();
        
    } catch {
        return 'error';
    }
}
