import {useCallback, useState } from "react"


export function useApiLeiten({apiService}){
    const [data, setData] = useState(null)

    const fetchApiService = useCallback( async (conditionData = '') => {
        try {
            const response = await apiService(conditionData)
            setData(response.data ? response.data : response)
        }
        catch(error) {
            console.log(error)
        }
    }, [apiService])

    const emptyApiService = () => {
        setData(null)
    }


    return [data, fetchApiService, emptyApiService]
}