import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'; 
import { ApiContext } from '../../contexts/ApiContext';
import Spinner from '../spinner/Spinner';
import SearchInput from './SearchInput';
import SelectBrand from '../selects/brandSelect';
// import { toBeRequired } from '@testing-library/jest-dom/matchers';

export default function Search(){

    const {loading,productById, emptyProductById, } = useContext(ApiContext);

    const navigate = useNavigate();

    //const [showButtonCode, setSohwButtonCode] = useState(true);
    const [showButtonBrand, setSohwButtonBrand] = useState(true);
    const [showButtonModel, setSohwButtonModel] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        emptyProductById()
    }, [])

    useEffect(() => {
        if(productById){
            const product = productById.producto;
            if(product){
                const brand = product.marca.toLowerCase().replace(/\s+/g, '-')//Paso toda la marca a minusculas y reemplazo espacios por guiones
                navigate(`/reparacion?brand=${brand}&product=${product.slug}`)
            }
        }
    }, [productById]) // eslint-disable-line react-hooks/exhaustive-deps


    

    // const showButtonCodeContent = () => {
    //     setSohwButtonCode(true);
    //     setSohwButtonBrand(false);
    //     setSohwButtonModel(false);
    // }

    if (loading) {
        return <Spinner />;
    }

    const showButtonBrandContent = () => {
        //setSohwButtonCode(false);
        setSohwButtonBrand(true);
        setSohwButtonModel(false);
    }

    const showButtonModelContent = () => {
        //setSohwButtonCode(false);
        setSohwButtonBrand(false);
        setSohwButtonModel(true);
    }

    const handleBrandChange = (event) => {
        const value = event.target.value;
        setSearchValue(value); // Actualiza el valor del select
        //navigate(`/producto`);
        navigate(`/reparacion?brand=${value}`); // Redirige automáticamente cuando se selecciona una marca
    }

    // const handleProductSelect = async (product) => {
    //     setFilteredProducts([]);
    //     await fetchProductById(product.id); 
    //     //navigate(`/producto`);
    //     navigate(`/reparacion?brand=${product.brand_slug}&product=${product.slug}`);
    // };

    return(
        <StyleSearch>
            <div className="row">
                <div className="col-md-12">
                    <div className="containerSearch">
                        <div className="containerButton ">
                            {/* <button className={showButtonCode ? 'activeButton buttonLeft' : 'deactivatedButton'} onClick={showButtonCodeContent}>Buscar por Código</button> */}
                            <button className={showButtonBrand ? 'activeButton buttonCenter' : 'deactivatedButton'} onClick={showButtonBrandContent}><h5>Buscar por Marca</h5></button>
                            <button className={showButtonModel ? 'activeButton buttonRight' : 'deactivatedButton buttonDesRight'} onClick={showButtonModelContent}><h5>Buscar por Modelo</h5></button>
                        </div>
                        <div className="containerInputSearch">
                            {showButtonModel ? (
                                <SearchInput />
                           ) : (
                               <SelectBrand searchValue={searchValue} onChange={handleBrandChange} />
                            )}
                            <button className='btn btn-warning' ><i className="fa-solid fa-magnifying-glass"></i></button>
                        </div>
                    </div>
                </div>
                {/* <div className="col-md-12 col-lg-5">
                    <div className="containerImgSearch">
                        <img src="../../../assets/img/category/repuestos4.jpeg" alt="imagen de un repuesto" />
                    </div>
                </div> */}
            </div>                            
        </StyleSearch>
    )
}

const StyleSearch = styled.div `

    .containerSearch{
       background-color: #fff;
       height: 100%;
       border-radius: 0.3rem;
       border-bottom: solid 0.1rem darkgray;
       border-left: solid 0.1rem darkgray;
       border-right: solid 0.1rem darkgray;
       position: relative;
    }
    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 160px;
        padding: 5px 0;
        margin: 2px 0 0;
        font-size: 14px;
        color: #333;
        text-align: left;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      }
      .dropdown-menu.show {
        display: block;
      }
    .containerButton{
        display: flex;
        justify-content: space-between;
        height: 5rem;
        color: #fff;
        gap: 0.4rem;
    }
    .activeButton {
        background-color: #FFF;
        color: #000;
        flex-grow: 1;
        border-top: solid 0.1rem darkgray;
        font-family: "Saira Condensed"
    }
    .buttonLeft{
        border-right: solid 0.1rem darkgray;
    }
    .buttonCenter{
        border-right: solid 0.1rem darkgray;
        //border-left: solid 0.1rem darkgray; //DESCOMENTAR CUANDO SE HABILITE EL BOTON POR CÓDIGO
    }
    .buttonRight{
        border-left: solid 0.1rem darkgray;
    }
    .deactivatedButton{
        background-color: var(--colorPrimario);
        color: #fff;
        flex-grow: 1;
        font-family: "Saira Condensed"
    }
    .buttonDesRight{
        border-right: solid 0.1rem var(--colorPrimario);
    }
    button {
        padding: 0rem 1rem;
    }
    .containerInputSearch{
        display: flex;
        margin: 5rem 0rem;
        padding: 0rem 1rem;
        flex-grow: 1;
        gap: 0.5rem;
    }
    .containerImgSearch {
        max-height: 30vh;
        overflow: hidden; /* Oculta el contenido que sobresale de la altura máxima */
        position: relative;
        margin-top: 1rem;
        @media (min-width: 992px){
            margin: 0px;
        }
    }

    img {
        position: relative;
        max-width: 100%; 
        top: 0vh;
        @media (min-width: 576px){
            top: -4vh;
        }
        @media (min-width: 768px){
            top: -10vh;
        }
        @media (min-width: 992px){
            top: -3vh;
            max-width: 150%;
        }
        @media (min-width: 1200px){
            max-width: 100%;
            top: 0vh;
        }
        @media (min-width: 1400px){
            top: -3vh;
        }
        @media (min-width: 1600px){
            top: -9vh;
        }
    }
`