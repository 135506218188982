import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Spinner from '../spinner/Spinner';
import { ApiContext } from '../../contexts/ApiContext';

export default function Filter({ slugBrandReceived, slugProductReceived, onProductSelect}) {
    const { brands, productsByBrand,fetchProductsByBrand, productById } = useContext(ApiContext);
    const [changesParams, setChangesParams] = useState(slugBrandReceived);
    const [loadingProducts, setLoadingProducts] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(productById ? productById.producto.id : null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); //eslint-disable-line

    //*Auto selecciona el select de las marcas en caso de que se haya seleccionado anteriormente
    useEffect(() => {
        const fetchSelectedBrands = async () => {
            try {
                if (slugBrandReceived) {
                    const brand = brands.find(brand => brand.brand_slug === slugBrandReceived);
                    if (brand) {
                        setSelectedBrand({ id: brand.id, slug: brand.nombre });
                    }
                }
            } catch (error) {
                console.error('Error fetching brands:', error);
            }
        };
        fetchSelectedBrands();
    }, [slugBrandReceived]); //eslint-disable-line

    //* Obtiene los productos que pertenezcan a la marca seleccionada
    useEffect(() => {
        const fetchSelectProductsByBrand = async () => {
            setLoadingProducts(true);
            try {
                if (selectedBrand) {
                    await fetchProductsByBrand(selectedBrand.slug);
                }
            } catch (error) {
                console.error('Error fetching products:', error);
            } finally {
                setLoadingProducts(false);
            }
        };

        fetchSelectProductsByBrand();
    }, [selectedBrand]); //eslint-disable-line

    //* En caso de que se busque el producto por otro medio, los select se actualizara de cualquier forma
    useEffect(() => {
        if (productById){
            
            if (selectedProduct != productById.producto.id){
                const brand = brands.find(brand => brand.brand_slug === productById.producto.marca.toLowerCase().replace(/\s+/g, '-'))
                selectedBrand !== brand.id && setSelectedBrand({id: brand.id, slug : brand.brand_slug}) //Paso toda la marca a minusculas y reemplazo espacios por guiones
                setSelectedProduct(productById.producto.id)
            }
        }
    }, [productById]) // eslint-disable-line

    const handleSelectChangeBrand = (event) => {
        const brandId = event.target.value;
        const brandSlug = event.target.options[event.target.selectedIndex].dataset.slug;
        setChangesParams(brandSlug);
        setSelectedBrand({ id: brandId, slug: brandSlug });
        setSelectedProduct(null); // Clear selected product when brand changes
    };

    const handleSelectChangeProduct = (event) => {
        const productId = event.target.value;
        setSelectedProduct(productId);
        onProductSelect(productId); // Call the callback with the selected product ID
    };

    return (
        <>
            <h5>Seleccione una Marca</h5>
            <div className="row customFilter">
                <div className="col-12">
                    <StyleFilterInput>
                        <select 
                            className="form-select"
                            value={selectedBrand?.id || ''} 
                            onChange={handleSelectChangeBrand} 
                        >
                            <option value="" disabled>Seleccione una marca</option>
                            {brands && brands.map(brand => (
                                <option 
                                    key={brand.id} 
                                    value={brand.id} 
                                    data-slug={brand.brand_slug}
                                >
                                    {brand.nombre}
                                </option>
                            ))}
                        </select>
                    </StyleFilterInput>
                </div>
            </div>
            {loadingProducts ? (
                <Spinner />
            ) : (
                productsByBrand && (
                    <StyleFilterInput>
                        <h5 className="product-heading">Seleccione un Producto</h5>
                        <div className="row productFilter">
                            <div className="col-12">
                                <select 
                                    value={selectedProduct || ''} 
                                    onChange={handleSelectChangeProduct} 
                                    className="form-select"
                                >
                                    <option value="" disabled>Seleccione un producto</option>
                                    {productsByBrand.map(product => (
                                        <option key={product.id} value={product.id}>
                                            {product.nombre_producto}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </StyleFilterInput>
                )
            )}
        </>
    );
}


const StyleFilterInput = styled.div`

    .product-heading {

        padding-bottom: 0.6rem;
        margin-top: 2rem !important;
    }
`;
