import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default function Card(props){

    if (props.origin !== 'api') {
        return (
            <StyleCard style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="row" style={{ width: '100%' }}>
                    {props.listCard.map((data) => (
                        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-2" key={data.id} style={{ marginBottom: '1rem' }}>
                            <Link to={data.link}>
                                <div className="card">
                                    <img src={data.img} className="card-img-top" alt={data.alt}/>
                                    <h4>{data.title}</h4>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </StyleCard>
        );
        
    } else {
        
        return (
            <StyleCardApi>
                {props.listCard.map((data) => (
                    <div className="card" style={{ ...props.cardStyles }} key={data.id}>
                        <div className="fondoImg">
                            <img    src={`https://leiten.com.ar/storage/${data.imagen_chica.replace(/^public\//, '')}`}
                                    className="card-img-top" alt={data.alt}/>
                        </div>
                        <h5>{data.nombre_producto}</h5>
                        {/* <h4>{data.}</h4> */}
                    </div>
                ))}
                
            </StyleCardApi>
        );
    }
}

const StyleCard = styled.div `
    .card{
        border: solid 0.1rem darkgray;
        display: flex;
    }
    h4{
        min-height: auto;
        padding: 1rem;
    },
`
const StyleCardApi = styled.div `
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: center;

    @media (min-width: 768px) {
        gap: 1rem;
    }

    .card {
        border: solid 0.1rem darkgray;
        flex: 1 1 calc(50% - 1rem); /* 2 cards per row on mobile */
        max-width: calc(50% - 1rem);

        @media (min-width: 768px) {
            flex: 1 1 calc(16.66% - 1rem); /* 6 cards per row on desktop */
            min-width: calc(15rem - 1rem);
            max-width: calc(16.66% - 1rem);   
        }
    }
    .fondoImg{
        background-color: #fff;
        text-align: center;
    }
    .card-img-top{
        background-color: #FFF; //QUITAR UN DIA SI CORRIGEN PNG EN LEITEN.COM.AR FUE UNA SOLUCION PRECARIA
        width: 80%;
        margin: 0 auto;
    }
    h5 {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
        min-height: 5rem;
    }
`