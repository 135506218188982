import { useEffect, useState } from 'react';
import { getKitMaintenance } from "../../service/ApiLeiten";
import Spinner from '../spinner/Spinner';
import Card from "../card/Card";

export default function CommonSpareParts(){

    const [listCommonSpareParts, setListCommonSpareParts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getKitMaintenance()
            .then(response => {
                // Obtener los 6 productos aleatorios
                const randomProducts = getRandomProducts(response.data, 6);
                // Almacenar los productos en el estado
                setListCommonSpareParts(randomProducts);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching kit maintenance:', error);
                setLoading(false);
            });
    }, []); // El segundo argumento [] asegura que el efecto se ejecute solo una vez al montar el componente

    // Función para obtener productos aleatorios
    const getRandomProducts = (products, count) => {
        const shuffled = products.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, count);
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        loading ? <Spinner /> : (
            <Card listCard={listCommonSpareParts} cardStyles={cardStyles} origin='api' />
        )
    );
}

const cardStyles = {
    width: '100%',
    backgroundColor: 'transparent',
    color: '#FFF'
};