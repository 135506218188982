import { useEffect, useContext, useState } from 'react';
import { ApiContext } from '../../contexts/ApiContext';

export default function SearchInput () {

    const {productSearch,fetchProductById} = useContext(ApiContext);
    const products = productSearch

    const [searchValue, setSearchValue] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);

    const handleInputChange = (event) => {
        const value = event.target.value;
        setSearchValue(value);
    };

    const handleProductSearch = async (product) => {
        setFilteredProducts([]);
        setTimeout(() => {
            setSearchValue(''); // Limpiar el input de búsqueda después de 2 segundos
        }, 2000);

        await fetchProductById(product.id);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (searchValue.trim().length >= 3) {
                const searchValueWithoutSpaces = searchValue.replace(/\s/g, '').toLowerCase();
                if (Array.isArray(products) && products.length > 0) {
                    const filtered = products.filter(product =>
                        product.nombre_producto.replace(/\s/g, '').toLowerCase().includes(searchValueWithoutSpaces)
                    );
                    setFilteredProducts(filtered);
                } else {
                    setFilteredProducts([]);
                }
            } else {
                setFilteredProducts([]);
            }
        }, 150); // Añadir debounce de 150ms
    
        return () => clearTimeout(timer); // Limpia el timeout al desmontar

    }, [searchValue, products]);

    return (
        <div className="dropdown" style={{ width: '100%' }}>
            <input
                className='form-control'
                type="text"
                placeholder='Busque su Equipo'
                value={searchValue}
                onChange={handleInputChange}
            />
            {searchValue.length >= 3 && filteredProducts.length > 0 && (
                <ul className="list-group dropdown-menu show" style={{width: '100%'}}>
                    {filteredProducts.map(product => (
                        <li
                            key={product.id}
                            className="list-group-item list-group-item-action"
                            onClick={() => handleProductSearch(product)}
                        >
                            {product.nombre_producto}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};