import Accordion from './Accordion';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid'; // Importar una función para generar IDs únicos
import { useState } from 'react';

export default function AccordionsGroup(props) {
  const failures = props.listFailure ? props.listFailure : [];
  const accordionGroupId = props.accordionGroupId || uuidv4(); // Generar un ID único para el grupo de accordion
  const [openAccordionId, setOpenAccordionId] = useState(null); // Estado para rastrear el acordeón abierto

  return (
    <StyledAccordionsGroup>
      <div className='col-12 containerAccordions'>
        <div className="accordion contenAccordion" id={accordionGroupId}>
          {failures.map((failure) => (
            <Accordion 
              key={failure.id} 
              idFail={failure.id} 
              listAFail={failure} 
              parentAccordionId={accordionGroupId} 
              isOpen={openAccordionId === failure.id}
              setOpenAccordionId={setOpenAccordionId}
            />
          ))}
        </div>
      </div>
    </StyledAccordionsGroup>
  );
}

const StyledAccordionsGroup = styled.div`
  .containerAccordions{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    width: 100%;
    @media (min-width: 768px){
      //margin-top: 2.5rem;
    }
  }
  .contenTitle{
    font-family: var(--fuentePrimaria);
  }
  .contenAccordion{
    //margin-top: 1.5rem;
    width: inherit;
    @media(min-width: 768px){
      width: 100%;
    }
  }
`