import React, { useEffect, useState } from 'react';
import { getSolutionsToPoduct, getBudget } from "../../service/ApiServicioTecnico";
import AccordionsGroup from '../accordion/AccordionsGroup';
import styled from 'styled-components';
import ModalMailTurno from '../modal/ModalMailTurno';
import ProductCardEspecial from '../card/ProductCardEspecial';

export default function RepairSelection({ productSlug }) {
    
    const [failure, setFailure] = useState(null);
    const [budget, setButget] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showModalRetiro, setShowModalRetiro] = useState(false);
    
    
    useEffect(() => {
        if (productSlug) {
            const fetchSolutions = async () => {                
                const responseSolutions = await getSolutionsToPoduct(productSlug.producto.id);                
                setFailure(responseSolutions);

                const responseBudget = await getBudget(productSlug.producto.id);
                setButget(responseBudget);
            };
            fetchSolutions();
        }
    }, [productSlug]);

    if (!productSlug) {
        return <h3 style={{ textAlign: 'center', margin: '2rem 0rem' }}>Seleccione una Marca y un Producto para ver su ficha técnica</h3>;
    }

    const handleShowModal = () => setShowModal(true); // Función para mostrar el modal
    const handleCloseModal = () => setShowModal(false); // Función para cerrar el modal

    const handleShowModalRetiro = () => setShowModalRetiro(true);
    const handleCloseModalRetiro = () => setShowModalRetiro(false);

    const { producto } = productSlug;

    return (
        <StyledRepairSelection>
            <div className="containerRepair">
                <div className="row" style={{width: '100%'}}>
                    <div className="col-sm-3 col-md-6 col-lg-4" >
                        <div className="row">
                            <div className="col-md-6 col-lg-12">
                                <img
                                    src={`https://leiten.com.ar/storage/${producto.imagen_grande.replace(/^public\//, '')}`}
                                    style={{ padding: '1rem 0rem', width: '100%' }}
                                    alt={`Imagen de ${producto.nombre_producto}`}
                                />
                                
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-12 col-lg-5">
                        {failure && failure.length > 0 ? 
                        <AccordionsGroup listFailure={failure}/>
                        : <h5 style={{ marginTop: '2rem' }}>No hay Soluciones disponibles para este producto</h5>}
                    </div>
                    
                    <div className="col-sm-3 col-md-6 col-lg-3">
                        <div className="containerBudget">
                            <h3 className='titleBudget'>REPARACIÓN STANDARD</h3>
                            { budget && budget.length > 0 && (
                                <>
                                    <h3 className='priceBudget'>USD {budget?.[0]?.reparacion_standard?.precio ?? ''} + iva</h3>
                                    <div className="containerPdfBudget btn" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        {/* <Link to={`${process.env.REACT_APP_TS_URL}/${budget[0].reparacion_standard.attachment}`}> */}
                                            <p className='subTitleBudget' style={{margin: '0rem 1rem'}}>Detalle de Presupuesto</p>
                                            <i className="fa-solid fa-cloud-arrow-down"></i>
                                        
                                    </div>

                                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-xl">
                                            <div class="modal-content">
                                            <div class="modal-header">
                                                <h2 class="modal-title fs-5" id="exampleModalLabel">Presupuesto Standard</h2>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body" style={{textAlign: 'center'}}>
                                            {budget?.[0]?.reparacion_standard?.attachment ? (
                                                <iframe
                                                    src={`${process.env.REACT_APP_TS_URL}/api/${budget[0].reparacion_standard.attachment}`}
                                                    frameBorder="0"
                                                    style={{ width: '100vh', height: '100vh' }}
                                                ></iframe>
                                            ) : (
                                                <p>No hay presupuesto disponible para mostrar.</p>
                                            )}                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className='containerButton'>
                                {/* <button className='btn btn-secondary buttonSecondary'>Presupuesto Mantenimiento Standard</button>
                                <button className='btn btn-secondary buttonSecondary'>Mantenimiento Preventivo</button> */}
                                <button 
                                    className='btn btn-secondary buttonSecondary'
                                    onClick={handleShowModal} // Al hacer clic, muestra el modal
                                    >
                                    Solicitar Turno
                                </button>
                                <button 
                                    className='btn btn-secondary buttonSecondary'
                                    onClick={handleShowModalRetiro} // Al hacer clic, muestra el modal
                                    >
                                    Solicitar Retiro
                                </button>
                            </div>
                        </div>
                        <div className="containerkit">
                            <ProductCardEspecial listSpartPart={producto} type="kit"/>
                        </div>
                    </div>
                    
                </div>
               
            </div>
            <ModalMailTurno show={showModal} handleClose={handleCloseModal} title={'Solicitar Turno por una Reparación'}/>
            <ModalMailTurno show={showModalRetiro} handleClose={handleCloseModalRetiro} title={'Solicitar Turno para Retiro'}/>
        </StyledRepairSelection>

    );
}

const StyledRepairSelection = styled.div`
    .containerRepair{
        //padding: 0rem 0rem 0rem 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1.5rem;
        margin-bottom: 2rem;
        @media (min-width: 992px){
            margin-top: 0rem;
        }
    }
    .containerBudget{
        padding: 0.5rem;
        border-radius: 0.5rem;
        background-color: var(--colorPrimarioClaro);
        height: auto;
    }
    .titleBudget{
        font-size: 2rem;
        font-weight: 700;
        text-align: center;
        margin: 0.5rem 0rem;
        appercase;
        color: var(--colorSecundario);
    }
    .priceBudget{
        font-size: 2.5rem;
        font-weight: 900;
        text-align: center;
        margin: 0.5rem 0rem;
        color: var(--colorSecundario);
        background-color: #5e5673;
        border-radius: 0.5rem;
        padding: 1.5rem;
        margin: 0 auto;
        width: 90%;
    }
    .containerPdfBudget{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: var(--colorSecundario);
        margin-top: 0.3rem;
    }
    .containerButton{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        margin-top: 1.5rem;
    }
    .buttonSecondary {
        margin: 0.3rem 0rem;
        width: 50%;
        font-weight: 700;
        font-size: 1.1rem;
    }
`