import { createContext, useState, useEffect} from "react";
import {getBrands, getProductsByBrand, getProductById, getProductPrice, getProductByCode, getKitMaintenance, getProductSearch} from '../service/ApiLeiten';
import { useApiLeiten } from "../hooks/useApiLeiten";

export const ApiContext = createContext();

export const ApiData = ({ children }) => {
    const [loading, setLoading] = useState(false);

    const [brands, fetchBrands] = useApiLeiten({apiService: getBrands})

    const [productsByBrand, fetchProductsByBrand, emptyProductByBrand] = useApiLeiten({apiService: getProductsByBrand})

    const [productById, fetchProductById, emptyProductById] = useApiLeiten({apiService: getProductById})
   
    const [productPrice, fetchProductPrice] = useApiLeiten({apiService: getProductPrice})
    
    const [productByCode, fetchProductByCode] = useApiLeiten({apiService: getProductByCode})

    const [kitMaintenance, fetchKitMaintenance] = useApiLeiten({apiService: getKitMaintenance})

    const [productSearch, fetchProductSearch] = useApiLeiten({apiService: getProductSearch})

    useEffect(() => {
        setLoading(true);

        if (!brands) {
            fetchBrands().finally(() => setLoading(false));
        }
    
        if (!productSearch) {
            fetchProductSearch().finally(() => setLoading(false));
        }
    }, []) //eslint-disable-line 

    return (
        <ApiContext.Provider value={{brands, fetchBrands, productsByBrand, fetchProductsByBrand, emptyProductByBrand, productById, fetchProductById, emptyProductById,
                                    productPrice, fetchProductPrice, productByCode, fetchProductByCode, kitMaintenance, fetchKitMaintenance, productSearch,
                                    fetchProductSearch, loading
        }}>
            {children}
        </ApiContext.Provider>
    );
};