import { useState, useEffect, useRef } from "react";
import styled from "styled-components";

export default function Modal(props) {
    const [urlTypeDoc, setUrlTypeDoc] = useState(null);
    const videoRef = useRef(null);
    const modalRef = useRef(null);

    useEffect(() => {
        if (props.data.type === 'varios' || props.data.type === 'img' || props.data.type === 'pdf') {
            setUrlTypeDoc('https://www.serviciotecnico.leiten.com.ar/api/portal-content/');
        } else if (props.data.type === 'video') {
            setUrlTypeDoc('https://player.vimeo.com/video/');
        }
    }, [props.data.type]);

    useEffect(() => {
        const handleHiddenModal = () => {
            if (videoRef.current) {
                videoRef.current.src = videoRef.current.src; // Reset the src to stop the video
            }
        };

        const modalElement = modalRef.current;
        if (modalElement) {
            modalElement.addEventListener('hidden.bs.modal', handleHiddenModal);
        }

        return () => {
            if (modalElement) {
                modalElement.removeEventListener('hidden.bs.modal', handleHiddenModal);
            }
        };
    }, []);

    return (
        <StyledModal>
            {/* <!-- Button trigger modal --> */}
            <button type="button" className="btn btn-warning" data-bs-toggle="modal" data-bs-target={`#modal-${props.id}`}>
                {props.data.name}
            </button>

            {/* <!-- Modal --> */}
            <div ref={modalRef} className="modal fade" id={`modal-${props.id}`} tabIndex="-1" aria-labelledby={`modalLabel-${props.id}`} aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id={`modalLabel-${props.id}`}>{props.data.name}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body" style={{ margin: '0 auto' }}>
                            {props.data.type === 'img' || props.data.type === 'varios' ? (
                                <img src={urlTypeDoc + props.data.id} alt={props.id} />
                            ) : props.data.type === 'pdf' ? (
                                
                                <iframe src={urlTypeDoc + props.data.id} frameBorder="0"
                                    style={{ width: '100vh', height: '100vh' }}></iframe>
                            ) : (

                                <div className="containerVideo">
                                    <iframe className="video" ref={videoRef}
                                        src={urlTypeDoc + props.data.attachment}
                                        frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen>
                                    </iframe>
                                    <script src="https://player.vimeo.com/api/player.js"></script>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </StyledModal>
    );
}

const StyledModal = styled.div`
    img {
        //width: ;
    }
    .modal-body {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }
    .containerVideo{
        width: 100%;
        height: 100%;
        margin-top: 1rem;
        
        @media (min-width: 768px){
            max-width: 100vh !important;
            height: 60vh !important;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0px;
            padding: 2rem 0rem;
        }
    }
    .video{
        width: 100%;
        height: 100%;    
        @media (min-width: 768px){
            margin: 0px;
            width: 100%;
            height: 100%;
        }
    }
`;
