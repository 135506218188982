import Banner from "../../component/banner/Banner";
import Category from "../../component/category/Category";
import CommonSpareParts from "../../component/commonSpareParts/CommonSpareParts";
import Maps from "../../component/maps/Maps";
import Search from "../../component/search/Search";
import TitleSection from "../../component/titleSection/TitleSection";

export default function HomeView (){
    return(
        <>
        <Banner banner={banner}/>
        {/*Meteria containerPortal en un componente nuevo*/}
        <div className="containerPortal">
            <div className="" style={{margin: 'var(--paddingSeccion)'}}>
                <TitleSection title={'Busca tu Equipo'}/>
                <p style={{marginBottom: '1.5rem'}}>Comprá repuestos para todos tus equipos Leiten</p>
                <Search/>
            </div>
            <div className="" style={{margin: 'var(--paddingSeccion)'}}>
                <TitleSection title={'Todas las categorías'}/>
                <Category/>
            </div>
        </div>
        {/** Meteria containerCommonSpartPart en un componente nuevo*/}
        <div className="containerCommonSpartPart" style={{backgroundColor: 'var(--colorPrimario)'}}>
            <div className="containerPortal">
                <div style={{padding: '3rem 0rem'}}>
                    <TitleSection specialStyles={specialStyles} title={'Repuestos más solicitados'}/>
                    <CommonSpareParts/>
                </div>
            </div>
        </div>
        <div className="containerPortal">
            <div style={{padding: '3rem 0rem'}}>
                <TitleSection title={'Conocé todos nuestros talleres'}/>
                <Maps/>
            </div>
        </div>
        </>
    )
}

const yearBase = 1992; // Año base que deseas asignar
const currentYear = new Date().getFullYear(); // Año actual
const yearsOfExperience = currentYear - yearBase; // Cálculo de años de experiencia

const banner = [
    {
        id: 1,
        title: `Más de ${yearsOfExperience} años de desarrollo y servicio concentrados en un solo lugar`,
        subTitle: 'Leiten, tu socio para construir',
        img: '../../../assets/img/banner/banner.jpg',
        alt: 'Imagen Banner'
    },
];

const specialStyles = {
    color: '#FFF',
};