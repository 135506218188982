import './App.css';
import { Route, Routes } from 'react-router-dom';

import LayoutSimple from './layouts/variations/LayoutSimple';

//VISTAS DE LANDING
import HomeView from './views/landing/HomeView';
import RepairView from './views/landing/RepairView';
import AccessoryView from './views/landing/AccessoryView';
import SpartPartView from './views/landing/SpartPartView';
import KitMaintenanceView from './views/landing/KitMaintenanceView';
import ManualsView from './views/landing/ManualsView';
import VisitWorkView from './views/landing/VisitWorkView';

export default function App() {
  return (
    <Routes>  
      {/* RUTAS PARA FRONT CLIENTE */}
      <Route path="/" element={<LayoutSimple />}>
        <Route index element={<HomeView />} />
        {/* <Route path="producto" element={<ProductView />} /> */}
        <Route path="reparacion" element={<RepairView />} />
        <Route path="accesorios" element={<AccessoryView />} />
        <Route path="repuestos" element={<SpartPartView />} />
        <Route path="kitMantenimientos" element={<KitMaintenanceView />} />
        <Route path="manuales" element={<ManualsView />} />
        <Route path="visitaObra" element={<VisitWorkView />} />
      </Route>
    </Routes>
  );
}