import React, { useEffect, useState } from 'react';
import { getProductById, getKitMaintenance } from '../../service/ApiLeiten';
import ApiDolar from '../../service/ApiDolar';
import Spinner from '../spinner/Spinner';
import styled from 'styled-components';

export default function ProductCardEspecial({ listSpartPart, type}) { //, onProductClick   agregar esto cuando use la simulacion de gogole fotos en card
    
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showDescription, setShowDescription] = useState({});

    const {dolar, euro} = ApiDolar();

    const toThousand = (n) => {
        const parts = n.toFixed(1).split('.');
        const integerPart = parts[0];
        const decimalPart = parts[1];
        
        const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        const result = `${formattedInteger},${decimalPart}`;
        
        return result;
    };
    
    useEffect(() => {
        let ids = [];
        
        if (type === 'accessory') {
            ids = listSpartPart.accessory_ids ? listSpartPart.accessory_ids.split(',') : [];
        } else if (type === 'spare_part') {
            ids = listSpartPart.spare_part_ids ? listSpartPart.spare_part_ids.split(',') : [];
        } else if (type === 'kit') {
            ids = listSpartPart.spare_part_ids ? listSpartPart.spare_part_ids.split(',') : [];
        }

        const fetchProducts = async () => {
            setLoading(true);
            setProducts([]); // Limpiar productos anteriores

            if (ids.length === 0) {
                setLoading(false); // No hay IDs para buscar, mostrar mensaje y parar el spinner
                return;
            }

            if (type !== 'kit') {
                try {
                    const productPromises = ids.map(id => getProductById(id.trim()));  // Mapeo de promesas sin usar await dentro del map
                    const productResponses = await Promise.all(productPromises);
                    //para ordenar los productos por nombre, en caso de no quererlo al final hay que eliminar el sortedProducts con su retunr y dejar solo setProducts
                    const sortedProducts = productResponses.map(response => response.data).sort((a, b) => {
                        return a.producto.modelo.localeCompare(b.producto.modelo);
                    });
                    setProducts(sortedProducts);
                } catch (error) {
                    console.error('Error fetching products:', error);
                } finally {
                    setLoading(false);
                }
            } else {
                try {
                    // Obtener los IDs de kitMaintenance
                    const kitMaintenanceResponse = await getKitMaintenance();
                    const kitMaintenanceIds = kitMaintenanceResponse.data.map(item => item.id.toString()); // Obtener IDs como strings
    
                    // Filtrar productos según los IDs de kitMaintenance
                    const productPromises = ids
                        .filter(id => kitMaintenanceIds.includes(id.trim()))
                        .map(id => getProductById(id.trim()));
    
                    const productResponses = await Promise.all(productPromises);
                    setProducts(productResponses.map(response => response.data));
                } catch (error) {
                    console.error('Error fetching products:', error);
                } finally {
                    setLoading(false);
                }
            }

        };

        fetchProducts();
    }, [listSpartPart, type]);

    const toggleDescription = (id) => {
        setShowDescription(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    if (loading) {
        return <Spinner />;
    }    

    return (
        <StyledProductCardEspecial>
            {products.length > 0 ? (
                products.slice(0, 1).map(product => (
                    product && product.producto ? (
                        <div className="card" key={product.producto.id} > 
                            {showDescription[product.producto.id] ? (
                                <>
                                {product.description &&  
                                    <div className="card-body" style={{ display: 'flex', alignItems: 'center' }}>
                                    <h5>{product.description}</h5>
                                    </div>
                                }
                                </>
                            ) : (
                                <>
                                {product.producto.imagen_grande && 
                                    <img src={`https://leiten.com.ar/${product.producto.imagen_grande.replace('public/', 'storage/')}`}
                                    className="card-img-top"
                                    style={{ padding: '1rem' }}
                                    alt={`Imagen de ${product.producto.nombre_producto}`}
                                    />
                                }

                                {product.producto.nombre_producto && product.producto.codigo && product.precio &&
                                
                                    <div className="card-body">
                                        <h4 className="card-title"><strong>{product.producto.nombre_producto}</strong></h4>
                                        {/* <p className="card-cod">Código: </p><h6 style={{minHeight: '3rem'}}>{product.producto.codigo}</h6> */}
                                        
                                        {product.precio.moneda_venta === 1 ? (
                                                <button className='btn btn-warning btnPrice'>U$S {product.precio.precio_venta.toString().replace('.', ',')} + iva</button>

                                        ) : (
                                                <button className='btn btn-warning btnPrice'>U$S: {toThousand((product.precio.precio_venta * euro)/dolar)} + iva</button>
                                        )
                                        }
                                    </div>
                                }
                                </>
                            )}
                        </div>
                    ) : (
                        <div key={Math.random()}>
                            <p>Producto no disponible</p>
                        </div>
                    )
                ))
            ) : (
                !loading && <h5 style={{ marginTop: '2rem' }}>No hay Datos disponibles para este producto</h5>
            )}
        </StyledProductCardEspecial>
    );
    
}

const StyledProductCardEspecial = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: center;
    max-height: 530px;  /* Limitar la altura máxima del contenedor */
    overflow-y: auto;   /* Habilitar el scroll vertical */
    scroll-snap-type: y mandatory;  /* Habilitar el scroll snap vertical */

    .card{
        margin-top: 0.5rem;
        background-color: var(--colorFondoPrincipal);
        border: none;
    }
    .card-img-top{
        margin: 0 auto;
        width: 80%
    }
    .logoInfo {
        display: flex;
        justify-content: end;
        padding: 0.5rem;
        cursor: pointer;
        font-size: 1.2rem;
    }

    .btnPrice{
        width: -webkit-fill-available;
        padding: 0.6rem;
    }
    .card-title{
        //min-height: 8rem;
        @media (min-width: 768px) {
            min-height: 2.5rem;
        }
    }
    .card-cod {
        margin-bottom: 0.2rem;
    }
`;
