import {useContext, useMemo} from 'react'
import { ApiContext } from '../../contexts/ApiContext';

export default function SelectBrand({searchValue, onChange}){
    const {brands} = useContext(ApiContext);

    const brandOptions = useMemo(() => {
        return brands && brands.map(brand => (
            <option key={brand.id} value={brand.brand_slug}>{brand.nombre}</option>
        ));
    }, [brands])

    return (
        <select className='form-control' value={searchValue} onChange={onChange}>
        <option value="">Seleccione una marca</option>
        {brandOptions}
        </select>
    )
}