import styled from 'styled-components';
import RepairSelecction from '../repair/RepairSelecction';
import SpartPartSelecction from '../spartPart/SpartPartSelecction';
import CommonSpartPartSelecction from '../commonSpareParts/CommonSparePartsSelecction';
import AccessorySelection from '../accessory/AccessorySelecction';
import ManualsSelection from '../manuals/ManualsSelecction';

export default function ProductSelection({ productSlug, sectionOrder }) {    

    const sections = {
        equipo: {
            title: " Repará tu equipo",
            icon: <i className="fa-solid fa-wrench" style={{ marginRight: '1rem' }}> </i>,
            component: productSlug ? <RepairSelecction productSlug={productSlug} /> : null,
        },
        repuestos: {
            title: "Repuestos",
            icon: <i className="fa-solid fa-gear" style={{ marginRight: '1rem' }}></i>,
            component: productSlug ? <SpartPartSelecction productSlug={productSlug} /> : null,
        },
        kit: {
            title: "Kit",
            icon: <i className="fa-solid fa-gears" style={{ marginRight: '1rem' }}></i>,
            component: productSlug ? <CommonSpartPartSelecction productSlug={productSlug} /> : null,
        },
        manuales: {
            title: "Manuales",
            icon: <i className="fa-solid fa-book-open" style={{ marginRight: '1rem' }}></i>,
            component: productSlug ? <ManualsSelection productSlug={productSlug} /> : null,
        },
        accesorios: {
            title: "Accesorios",
            icon: <i className="fa-solid fa-plug" style={{ marginRight: '1rem' }}></i>,
            component: productSlug ? <AccessorySelection productSlug={productSlug} /> : null,
        }
    };

    if (!productSlug) {
        return <h3 style={{ textAlign: 'center', margin: '2rem 0rem' }}>Seleccione una Marca y un Producto para ver Todo</h3>;
    }

    return (
        <div className="accordion" id="accordionExample">
            {sectionOrder && sectionOrder.map((key, index) => {
                const section = sections[key];
                return (
                    <div className="accordion-item" key={key}>
                        <h2 className="accordion-header" id={`heading${index}`}>
                            <div className="opacidad"></div>
                            <AccordionButton
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}`}
                                aria-expanded={index === 0 ? "true" : "false"}
                                aria-controls={`collapse${index}`}
                                style={{fontWeight: '700', fontSize: '1.5rem'}}
                            >
                                {section.icon}{section.title}
                            </AccordionButton>
                        </h2>
                        <div
                            id={`collapse${index}`}
                            className={`accordion-collapse collapse ${index === 0 ? "show" : ""}`}
                            aria-labelledby={`heading${index}`}
                            data-bs-parent="#accordionExample"
                        >
                            <div className="accordion-body" >
                                {section.component}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}


// Botón del acordeón con el estilo de fondo
const AccordionButton = styled.button`
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: -webkit-fill-available;
`;
