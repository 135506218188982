import styled from 'styled-components';

export default function Spinner(){
    return(
        <StyledSpinner>
        <div className="containerSpinner">

            <div className="spinner-border text-warning" role="status">
                <span className="visually-hidden">Cargando...</span>
            </div>
        </div>
        </StyledSpinner>
    )
}

const StyledSpinner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
`