import { useState, useEffect } from "react";
import { getSolutiontToProductId, getSolutionsToPoduct } from "../../service/ApiServicioTecnico";
import styled from "styled-components";
import AccordionsGroup from "./AccordionsGroup";
import Spinner from "../spinner/Spinner";
import Modal from "../modal/Modal";

export default function Accordion(props) {
    const [solutions, setSolutions] = useState(null); // Falla y soluciones asociadas
    const [loading, setLoading] = useState(false);

    const fetchSolutionsById = async () => {
        setLoading(true);
        try {
            const response = await getSolutiontToProductId(props.listAFail.type_id, props.listAFail.solution_id);
            setSolutions(response.data); // Guardamos la respuesta completa
        } catch (error) {
            console.error('Error fetching solutions:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleAccordionClick = () => {
        if (!props.isOpen) {
            props.setOpenAccordionId(props.listAFail.id);
            fetchSolutionsById();
        } else {
            props.setOpenAccordionId(null);
        }
    };

    return (
        <StyledAccordion>
            <div className="accordion-item" id={'heading' + props.listAFail.id}>
                <h3 className="accordion-header">
                    <button className={`accordion-button ${props.isOpen ? '' : 'collapsed'}`}
                            type="button"
                            onClick={handleAccordionClick}
                            style={{fontWeight: '700', fontSize: '1.2rem'}}>
                            {/* Muestra el nombre de la falla */}
                            {props.listAFail.item_solution ? props.listAFail.item_solution.name : 'Consultar por estado de solución'}
                    </button>
                </h3>
                <div id={"flush-collapseOne" + props.listAFail.id} className={`accordion-collapse collapse ${props.isOpen ? 'show' : ''}`}
                     aria-labelledby={"flush-headingOne" + props.listAFail.id}
                     data-bs-parent={"#" + props.parentAccordionId}>
                    
                    <div className="accordion-body">
                        {loading ? (
                            <>
                                <Spinner/>
                                <p>Cargando soluciones...</p>
                            </>
                        ) : (
                            solutions ? (
                                <>
                                    {/* Mostrar documentos si existen */}
                                    {solutions.documentos.length > 0 && (
                                        <div>
                                            {solutions.documentos.map((documento, index) => (
                                                documento.state === 'approved' ? (
                                                    <div key={documento.id} style={{margin: '0.8rem 0rem'}}>
                                                        <Modal id={`modal-${props.listAFail.id}-${index}`} data={documento} />
                                                    </div>
                                                ) : null
                                            ))}
                                        </div>
                                    )}
                        
                                    {/* Acordeón para subsoluciones */}
                                    {solutions.subsoluciones.length > 0 ? (
                                        <AccordionsGroup 
                                            listFailure={solutions.subsoluciones}  // Pasamos las subsoluciones
                                            accordionGroupId={"subAccordionGroup" + props.listAFail.id}
                                        />
                                    ) : null}
                        
                                    {/* Mostrar mensaje solo si no hay documentos ni subsoluciones */}
                                    {solutions.documentos.length === 0 && solutions.subsoluciones.length === 0 && (
                                        <p>No hay subsoluciones ni documentos disponibles</p>
                                    )}
                                </>
                            ) : (
                                <p>No hay soluciones disponibles</p>
                            )
                        )
                        }
                    </div>
                </div>
            </div>
        </StyledAccordion>
    );
}

const StyledAccordion = styled.div`
    .accordion-header {
        display: flex;
        align-items: center;
  }

  .accordion-button {
        padding: 1rem;
        margin: 0;
        flex-grow: 1;
  }
`