import Styled from "styled-components";

export default function Banner(props){
    return(
        <StyledBanner>  
            {props.banner.map((data) => (
                <div key={data.id}>
                    {/* <div className="opacity"></div> */}
                    <div className="containerBanner" style={{ backgroundImage: `url(${data.img})` }}>
                        <div className="container text-center containerText">
                            <h1 className="titleBanner">{data.title}</h1>
                            <h3>{data.subTitle}</h3>
                        </div>
                    </div>
                </div>
            ))}
        </StyledBanner>
    )
}

const StyledBanner = Styled.div`
    .bannerContainer {
        position: relative;
    }
    // .opacity{
    //     position: absolute;
    //     top: var(--alturaBarraMobile);
    //     left: 0;
    //     width: 100%;
    //     height: 20rem;
    //     background-color: rgba(0, 0, 0, 0.3); /* Color negro con transparencia */
    //     @media (min-width: 992px){
    //         top:var(--alturaBarra);
    //     }
    // }
    .containerText {
        position: relative; /* Asegura que .containerText esté posicionado relativo para que su z-index funcione */
        z-index: 10; /* Posiciona .containerText por encima de .opacity */
    }
    .containerBanner{
        display: flex;
        flex-direction: column;
        height: 15rem;
        background-repeat: no-repeat;
        background-size: 165%;
        background-position-y: -80px;
        // margin-bottom: 2rem;
        //css para texto
        display: flex;
        align-items: center;
        justify-content: center;
        color: #f1f1f1;
        @media (min-width: 576px){
            height: 20rem;
            background-position-y: -200px;
        }
        @media (min-width: 768px){
            background-position-y: -250px;
        }
        @media (min-width: 992px){
            background-position-y: -350px;
        }
        @media (min-width: 1200px){
            background-position-y: -400px;
        }
        @media (min-width: 1400px){
            background-size: 100%;
            background-position-y: -450px;
        }
    }
    .titleBanner{
        font-size: 2rem;
        @media (min-width: 768px){
            font-size: 3rem;
        }
    }
`