import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { APIProvider, Map, AdvancedMarker, Pin} from "@vis.gl/react-google-maps"; //, InfoWindow
import ApiBranch from "../../service/ApiBranchs";

export default function Maps() {
  const branches = ApiBranch();

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth);

  };

  const defaultZoom = width < '768' ? 4 : 5;

  const [selectedBranch, setSelectedBranch] = useState([""]);

  // const [openBsAs, setOpenBsAs] = useState(false);
  // const [openTuc, setOpenTuc] = useState(false);
  // const [openRos, setOpenRos] = useState(false);
  // const [openSF, setOpenSF] = useState(false);
  // const [openCorri, setOpenCorri] = useState(false);
  // const [openCor, setOpenCor] = useState(false);
  // const [openMen, setOpenMen] = useState(false);
  // const [openNeu, setOpenNeu] = useState(false);

  const positionGlobal = { lat: -33, lng: -62 };
  const bsas = { lat: -34.624966458619596, lng: -58.56756012486912 };
  const rosario = { lat: -32.97771996999018, lng: -60.73052484773732 };
  const santafe = { lat: -31.605444024183505, lng: -60.69164147382471 };
  const corrientes = { lat: -27.483921929840623, lng: -58.79894487724042 };
  const cordoba = { lat: -31.451445215709143, lng: -64.25318253318771 };
  const mendoza = { lat: -32.89931924231799, lng: -68.83158927192535 };
  const neuquen = { lat: -38.95321353201833, lng: -68.02410513122399 };
  const tucuman = { lat: -26.422458884381022, lng: -65.31011637662503 };
  
  const handleMarkerClick = (branch) => {
    setSelectedBranch(branch);
  };
  
  return (
    <StyledMaps>
      <div className="row">
        <div className="col-md-12 col-lg-8">
          <APIProvider apiKey={process.env.REACT_APP_MAPS_KEY}>
              <Map style={{ height: '50vh', width: '100%' }} defaultZoom={defaultZoom} defaultCenter={positionGlobal} gestureHandling={'greedy'} mapId={process.env.REACT_APP_MAPS_ID}>

                <AdvancedMarker position={bsas} onClick={() => handleMarkerClick(branches[0])}>
                  <Pin  background={"#282530"} borderColor={"#282530"} glyphColor={"#FFC629"}/>
                </AdvancedMarker>
                {/* {openBsAs && 
                    <InfoWindow position={bsas} onCloseClick={() => setOpenBsAs(false)}>
                        <p>Direccion: {branches[0].direccion}</p>
                        <p>Localidad: {branches[0].localidad}</p>
                        <p>Horaio: {branches[0].horarios}</p>
                        <p>Telefono: {branches[0].tel_fijo}</p>
                    </InfoWindow>} */}

                <AdvancedMarker position={rosario} onClick={() => handleMarkerClick(branches[2])}>
                  <Pin  background={"#282530"} borderColor={"#282530"} glyphColor={"#FFC629"}/>
                </AdvancedMarker>
                {/* {openRos && 
                    <InfoWindow position={rosario} onCloseClick={() => setOpenRos(false)}>
                        <p>Direccion: {branches[2].direccion}</p>
                        <p>Localidad: {branches[2].localidad}</p>
                        <p>Horaio: {branches[2].horarios}</p>
                        <p>Telefono: {branches[2].tel_fijo}</p>
                    </InfoWindow>} */}

                <AdvancedMarker position={santafe} onClick={() => handleMarkerClick(branches[3])}>
                  <Pin  background={"#282530"} borderColor={"#282530"} glyphColor={"#FFC629"}/>
                </AdvancedMarker>
                {/* {openSF && 
                    <InfoWindow position={santafe} onCloseClick={() => setOpenSF(false)}>
                        <p>Direccion: {branches[3].direccion}</p>
                        <p>Localidad: {branches[3].localidad}</p>
                        <p>Horaio: {branches[3].horarios}</p>
                        <p>Telefono: {branches[3].tel_fijo}</p>
                    </InfoWindow>} */}

                <AdvancedMarker position={corrientes} onClick={() => handleMarkerClick(branches[4])}>
                  <Pin  background={"#282530"} borderColor={"#282530"} glyphColor={"#FFC629"}/>
                </AdvancedMarker>
                {/* {openCorri && 
                    <InfoWindow position={corrientes} onCloseClick={() => setOpenCorri(false)}>
                        <p>Direccion: {branches[4].direccion}</p>
                        <p>Localidad: {branches[4].localidad}</p>
                        <p>Horaio: {branches[4].horarios}</p>
                        <p>Telefono: {branches[4].tel_fijo}</p>
                    </InfoWindow>} */}

                <AdvancedMarker position={cordoba} onClick={() => handleMarkerClick(branches[5])}>
                  <Pin  background={"#282530"} borderColor={"#282530"} glyphColor={"#FFC629"}/>
                </AdvancedMarker>
                {/* {openCor && 
                    <InfoWindow position={cordoba} onCloseClick={() => setOpenCor(false)}>
                        <p>Direccion: {branches[5].direccion}</p>
                        <p>Localidad: {branches[5].localidad}</p>
                        <p>Horaio: {branches[5].horarios}</p>
                        <p>Telefono: {branches[5].tel_fijo}</p>
                    </InfoWindow>} */}

                <AdvancedMarker position={mendoza} onClick={() => handleMarkerClick(branches[6])}>
                  <Pin  background={"#282530"} borderColor={"#282530"} glyphColor={"#FFC629"}/>
                </AdvancedMarker>
                {/* {openMen && 
                    <InfoWindow position={mendoza} onCloseClick={() => setOpenMen(false)}>
                        <p>Direccion: {branches[6].direccion}</p>
                        <p>Localidad: {branches[6].localidad}</p>
                        <p>Horaio: {branches[6].horarios}</p>
                        <p>Telefono: {branches[6].tel_fijo}</p>
                    </InfoWindow>} */}

                <AdvancedMarker position={neuquen} onClick={() => handleMarkerClick(branches[7])}>
                  <Pin  background={"#282530"} borderColor={"#282530"} glyphColor={"#FFC629"}/>
                </AdvancedMarker>
                {/* {openNeu && 
                    <InfoWindow position={neuquen} onCloseClick={() => setOpenNeu(false)}>
                        <p>Direccion: {branches[7].direccion}</p>
                        <p>Localidad: {branches[7].localidad}</p>
                        <p>Horaio: {branches[7].horarios}</p>
                        <p>Telefono: {branches[7].tel_fijo}</p>
                    </InfoWindow>} */}

                <AdvancedMarker position={tucuman} onClick={() => handleMarkerClick(branches[1])}>
                  <Pin  background={"#282530"} borderColor={"#282530"} glyphColor={"#FFC629"}/>
                </AdvancedMarker>
                {/* {openTuc && 
                    <InfoWindow position={tucuman} onCloseClick={() => setOpenTuc(false)}>
                        <p>Direccion: {branches[4].direccion}</p>
                        <p>Localidad: {branches[4].localidad}</p>
                        <p>Horaio: {branches[4].horarios}</p>
                        <p>Telefono: {branches[4].tel_fijo}</p>
                    </InfoWindow>} */}
              </Map>
          </APIProvider>
        </div>
        <div className="col-md-12 col-lg-4">
          <div className="containerInfo">
            <div className="containerTitle">
              <h3 className="styleTitle">Taller Seleccionado</h3>

            </div>
            {selectedBranch && (
                <div>
                  <p><strong>Direccion: </strong>{selectedBranch.direccion}</p>
                  <p><strong>Localidad: </strong>{selectedBranch.localidad}</p>
                  <p><strong>Horario: </strong>{selectedBranch.horarios}</p>
                  <p><strong>Telefono: </strong>{selectedBranch.tel_fijo}</p>
                  <p><strong>Celular: </strong>{selectedBranch.tel_movil}</p>
                </div>
              )}
          </div>
        </div>
      </div>   
    </StyledMaps>
  );
}

const StyledMaps = styled.div`
  .containerInfo {
    background-color: #FFF;
    border-radius: 1rem;
    border: solid 0.1rem darkgray;
    padding: 2rem;
    height: 50vh;
    margin-top: 1rem;

    @media(min-width: 768px) {
      margin-top: 0rem;
  }
    p {
        font-size: 1.1rem;
        margin: 1rem 0rem;
    }

    // .styleCol{
    //     @media (min-width: 1200px){
    //         width: 12%;
    //     }
    // }
    // .contenBranch {
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     width: 100%;
    //     //padding: 1rem;
    // }
    // .contenMap {
    //     width: 100%;
    // }
    // h4 {
    //     font-size: 1rem;
    //     margin: 0.5rem 0;
    // }
    // p {
    //     font-size: 0.8rem;
    //     margin: 0;
    // }
`