import { useState, useEffect } from 'react';
import styled from 'styled-components';
import ProductCard from '../card/ProductCard';

export default function CommonSpartPartSelecction({ productSlug }) {

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
    const [showModal, setShowModal] = useState(false);
    
    const handleProductClick = (product) => {
        setSelectedProduct(product);
        if (isMobile) {
            setShowModal(true); // Abrir el modal si es un dispositivo móvil
        }
    };

    const handleCloseClick = () => {
        setSelectedProduct(null);
        setShowModal(false); // Cerrar el modal
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 992);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (!productSlug) {
        return <h3 style={{textAlign: 'center', margin: '2rem 0rem'}}> Seleccione una Marca y un Producto para ver sus Kit</h3>;
    }

    const { producto } = productSlug;

    return (
        <>
        <div className="containerSpartParts">
            <ProductCard listSpartPart={producto} type="kit" onProductClick={handleProductClick} />
        </div>
        </>
    );
}

const StyledCommonSpartPartSelection = styled.div`
    .containerSpartParts{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2rem 0rem;
        @media (min-width: 992px){
            padding: 0rem 0rem 0rem 3rem;
        }
    }
    .prueba{
    
        width: 100%;
        height: 100%;
    }
    .prueba2{
        display: flex;
        flex-direction: column;
        background-color: #fff;
        margin-bottom: 2rem;
        position: -webkit-sticky; /* For Safari */
        position: sticky;
        top: 20rem; 
    }
`