import Card from "../card/Card";

export default function Category(){
    return(
        <Card listCard={listCategory}/>
    )
}

//* Esto habria que guardarlo en una carpeta de constantes
const listCategory = 
[
    { id:'category1', title: "Reparar Equipo", link:"/reparacion", img: "../../../assets/img/category/reparar.jpeg", alt: "Imagen de repacion de equipos"},
    { id:'category2', title: "Repuestos", link:"/repuestos", img: "../../../assets/img/category/repuestos3.jpeg", alt: "Imagen de repuestos"},
    { id:'category3', title: "Mantenimiento", link:"/kitMantenimientos", img: "../../../assets/img/category/mantenimiento.jpeg", alt: "Imagen de mantenimiento"},
    { id:'category4', title: "Accesorios", link:"/accesorios", img: "../../../assets/img/category/accesorios.jpeg", alt: "Imagen de accesorios"},
    { id:'category5', title: "Manuales", link:"/manuales", img: "../../../assets/img/category/manuales.jpeg", alt: "Imagen de manuales"},
    { id:'category6', title: "Visita a Obra", link:"/visitaObra", img: "../../../assets/img/category/obras.jpeg", alt: "Imagen de visita a obras"},
];